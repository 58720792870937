import { Typography } from "@material-ui/core"
import { Variant } from "@material-ui/core/styles/createTypography"
import React from "react"

interface Props {
  variant?: Variant
  component?: React.ElementType
}

const Headline: React.FC<Props> = ({ variant = "body1", component = "div", children }) => {
  return (
    <Typography variant={variant} component={component} gutterBottom>
      {children}
    </Typography>
  )
}

export default Headline
