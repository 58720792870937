import { DateTime } from "luxon"
import React from "react"

interface Props {
  value: string | null | undefined
  fallback?: string
}

const StringToTime: React.FC<Props> = ({ value, fallback }) => {
  let date: DateTime | undefined
  if (value) {
    date = DateTime.fromISO(value, { zone: "UTC" }).setZone(DateTime.local().zoneName)
  }
  if (!date || !date.isValid) return <>{fallback || "-"}</>
  return <>{date.toFormat("HH:mm:ss")}</>
}

export default StringToTime
