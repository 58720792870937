import { Container } from "@material-ui/core"
import { styled } from "@material-ui/styles"
import React from "react"

interface Props {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
}

const PageContainer: React.FC<Props> = (props) => {
  return <StyledContainer maxWidth={props.maxWidth || "sm"}>{props.children}</StyledContainer>
}

const StyledContainer = styled(Container)({
  padding: "12px",
})

export default PageContainer
