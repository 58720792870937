export enum AgentTypes {
  AEP = "AEP",
  SANACORP = "SANACORP",
  PHOENIX = "PHOENIX",
}

export interface IPzn {
  id: string
  agent: AgentTypes
  pzn: number
}

export interface RestrictedArticle {
  id: string
  pzn: number
  ipzns: IPzn[]
  name: string
  bufferPercentage: number
  minBufferAmount: number
  maxBufferAmount: number
  minExpectedDemand: number
  maxExpectedDemand: number
  activeFrom: string
  activeTo?: string | null
}

export interface RestrictedArticlesApiResponse {
  restrictedArticles: RestrictedArticle[]
}

export interface RestrictedArticleApiResponse extends RestrictedArticle {}
