import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import React, { FormEvent } from "react"

interface Props {
  disabled?: boolean
  id: string
  title: string
  submitButtonText?: string
  onConfirm: (id: string) => void
  renderTrigger: (openConfirmModal: () => void) => JSX.Element
}

interface State {
  isDialogOpen: boolean
}

class ConfirmDialog extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isDialogOpen: false,
    }
  }

  openDialog = (): void => this.setState({ isDialogOpen: true })
  closeDialog = (event: {}, reason: "backdropClick" | "escapeKeyDown" | "button"): void => {
    if (!this.props.disabled && reason !== "backdropClick" && reason !== "escapeKeyDown") {
      this.setState({ isDialogOpen: false })
    }
  }

  handleSubmit = (event: FormEvent): void => {
    event.preventDefault()
    this.props.onConfirm(this.props.id)
  }

  render(): JSX.Element {
    return (
      <>
        {this.props.renderTrigger(this.openDialog)}
        <Dialog onClose={this.closeDialog} open={this.state.isDialogOpen}>
          <form onSubmit={this.handleSubmit}>
            <DialogTitle>{this.props.id} wirklich löschen?</DialogTitle>
            <DialogContent>{this.props.children}</DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={() => this.closeDialog({}, "button")} disabled={this.props.disabled}>
                Abbrechen
              </Button>
              <Button color="primary" disabled={this.props.disabled} type="submit" autoFocus>
                {this.props.submitButtonText || "Bestätigen"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    )
  }
}

export default ConfirmDialog
