import { Divider, FormControl, Grid, Paper, Select, Typography } from "@material-ui/core"
import { styled } from "@material-ui/styles"
import React from "react"
import { Loads } from "react-loads"
import { RouteComponentProps, withRouter } from "react-router"

import config from "../../config"
import Headline from "../../elements/Headline"
import PageContainer from "../../elements/PageContainer"
import AuthenticationService from "../authentication/authentication.service"
import { PharmaciesApiResponse } from "../Pharmacies/types"
import { RestrictedArticlesApiResponse } from "../RestrictedArticles/types"
import { LoadRenderProps } from "../types"
import Bookings from "./Bookings/Bookings"
import Buffer from "./Buffer/Buffer"
import Quotas from "./Quotas/Quotas"

type PharmaciesPromise = Promise<PharmaciesApiResponse>
type RestrictedArticlesPromise = Promise<RestrictedArticlesApiResponse>

export interface Props extends RouteComponentProps {
  pharmacies: LoadRenderProps<Unpromise<PharmaciesPromise>>
  restrictedArticles: LoadRenderProps<Unpromise<RestrictedArticlesPromise>>
}

class Contingents extends React.PureComponent<Props> {
  getQueryParam = (key: string): string | undefined => {
    return new URLSearchParams(this.props.location.search).get(key) || undefined
  }

  handleSelectChange =
    (key: string) =>
    (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
      const value = (event.target.value as string) || ""
      const searchParams = new URLSearchParams(this.props.location.search)
      searchParams.set(key, value)
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: `?${searchParams.toString()}`,
      })
    }

  render(): JSX.Element {
    const pharmacyId = this.getQueryParam("pharmacyId")
    const restrictedArticleId = this.getQueryParam("restrictedArticleId")
    const pharmacy = this.props.pharmacies.response?.pharmacies.find((phamacy) => phamacy.id.toString() === pharmacyId)
    return (
      <PageContainer maxWidth="xl">
        <Headline variant="h4" component="h1">
          Kontingente
        </Headline>
        <StyledPaper>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl variant="outlined" fullWidth margin="dense">
                {this.props.pharmacies.isPending && (
                  <Select native defaultValue="" fullWidth disabled>
                    <option value="" disabled>
                      Apotheken werden geladen
                    </option>
                  </Select>
                )}
                {this.props.pharmacies.isRejected && (
                  <Select native defaultValue="" fullWidth disabled>
                    <option value="" disabled>
                      Apotheken konnten nicht geladen werden
                    </option>
                  </Select>
                )}
                {this.props.pharmacies.isResolved && (
                  <Select native value={pharmacyId || ""} onChange={this.handleSelectChange("pharmacyId")} fullWidth>
                    {this.props.pharmacies.response && this.props.pharmacies.response.pharmacies.length === 0 && (
                      <option value="" disabled>
                        Keine Apotheke verfügbar
                      </option>
                    )}
                    {this.props.pharmacies.response && (
                      <>
                        {!pharmacyId && (
                          <option value="" disabled>
                            Apotheke auswählen
                          </option>
                        )}
                        {this.props.pharmacies.response.pharmacies.map((pharmacy) => (
                          <option key={pharmacy.id} value={pharmacy.id}>
                            {pharmacy.name} ({pharmacy.idf})
                          </option>
                        ))}
                      </>
                    )}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl variant="outlined" fullWidth margin="dense">
                {this.props.restrictedArticles.isPending && (
                  <Select native defaultValue="" fullWidth disabled>
                    <option value="" disabled>
                      Artikel werden geladen
                    </option>
                  </Select>
                )}
                {this.props.restrictedArticles.isRejected && (
                  <Select native defaultValue="" fullWidth disabled>
                    <option value="" disabled>
                      Artikel konnten nicht geladen werden
                    </option>
                  </Select>
                )}
                {this.props.restrictedArticles.isResolved && (
                  <Select
                    native
                    value={restrictedArticleId || ""}
                    onChange={this.handleSelectChange("restrictedArticleId")}
                    fullWidth
                  >
                    {this.props.restrictedArticles.response &&
                      this.props.restrictedArticles.response.restrictedArticles.length === 0 && (
                        <option value="" disabled>
                          Keine Artikel verfügbar
                        </option>
                      )}
                    {this.props.restrictedArticles.response && (
                      <>
                        {!restrictedArticleId && (
                          <option value="" disabled>
                            Artikel auswählen
                          </option>
                        )}
                        {this.props.restrictedArticles.response.restrictedArticles.map((article) => (
                          <option key={article.id} value={article.id}>
                            {article.name} ({article.pzn})
                          </option>
                        ))}
                      </>
                    )}
                  </Select>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {pharmacyId && restrictedArticleId && pharmacy && (
            <>
              <StyledDivider />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    Berechnungen
                  </Typography>
                  <Quotas pharmacyId={pharmacyId} restrictedArticleId={restrictedArticleId} />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    Puffer und Grenzen
                  </Typography>
                  <Buffer restrictedArticleId={restrictedArticleId} />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    Buchungen{" "}
                    {pharmacy?.quotaTimespanInDays !== 1
                      ? `im Zeitraum ${pharmacy.quotaTimespanInDays} Tage (inkl. heute)`
                      : "heute"}
                  </Typography>
                  <Bookings
                    pharmacyId={pharmacyId}
                    restrictedArticleId={restrictedArticleId}
                    quotaTimespanInDays={pharmacy?.quotaTimespanInDays}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </StyledPaper>
      </PageContainer>
    )
  }
}

const StyledPaper = styled(Paper)({
  padding: "12px",
})

const StyledDivider = styled(Divider)({
  margin: "12px 0",
})

const getPharmaciesPromise = (): PharmaciesPromise =>
  AuthenticationService.fetch<PharmaciesPromise>(`${config.backend.url}/pharmacies`).then((response) => response.body)

const getRestrictedArticlesPromise = (): RestrictedArticlesPromise =>
  AuthenticationService.fetch<RestrictedArticlesApiResponse>(`${config.backend.url}/restricted-articles`).then(
    (response) => response.body
  )

export const WithRouterContingents = withRouter(Contingents)
export default (): JSX.Element => (
  <Loads load={getPharmaciesPromise}>
    {(pharmacies) => (
      <Loads load={getRestrictedArticlesPromise}>
        {(restrictedArticles) => (
          <WithRouterContingents pharmacies={pharmacies} restrictedArticles={restrictedArticles} />
        )}
      </Loads>
    )}
  </Loads>
)
