import { Box, CircularProgress } from "@material-ui/core"
import React from "react"

const PageProgress: React.FC = () => {
  return (
    <Box width="100%" textAlign="center">
      <CircularProgress />
    </Box>
  )
}

export default PageProgress
