import { List, ListItem, ListItemText } from "@material-ui/core"
import { DateTime } from "luxon"
import React from "react"
import { Loads } from "react-loads"

import config from "../../../config"
import PageError from "../../../elements/PageError"
import PageProgress from "../../../elements/PageProgress"
import StringToDateTime from "../../../elements/StringToDateTime"
import StringToTime from "../../../elements/StringToTime"
import AuthenticationService from "../../authentication/authentication.service"
import { LoadRenderProps } from "../../types"
import { BookingsApiResponse } from "./types"

type BookingsPromise = Promise<BookingsApiResponse>

export interface Props {
  pharmacyId: string
  restrictedArticleId: string
  bookings: LoadRenderProps<Unpromise<BookingsPromise>>
  quotaTimespanInDays?: number
}

export class Bookings extends React.PureComponent<Props> {
  componentDidMount(): void {
    this.props.bookings.load({
      pharmacyId: this.props.pharmacyId,
      restrictedArticleId: this.props.restrictedArticleId,
      quotaTimespanInDays: this.props.quotaTimespanInDays,
    })
  }
  componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.pharmacyId !== this.props.pharmacyId ||
      prevProps.restrictedArticleId !== this.props.restrictedArticleId
    ) {
      this.props.bookings.load({
        pharmacyId: this.props.pharmacyId,
        restrictedArticleId: this.props.restrictedArticleId,
        quotaTimespanInDays: this.props.quotaTimespanInDays,
      })
    }
  }
  render(): JSX.Element {
    let bookings = this.props.bookings.response ? this.props.bookings.response.bookings : []
    const today = !this.props.quotaTimespanInDays || this.props.quotaTimespanInDays === 1
    bookings = bookings.sort(
      (left, right) => DateTime.fromISO(right.date).toMillis() - DateTime.fromISO(left.date).toMillis()
    )
    return (
      <>
        {this.props.bookings.isPending && <PageProgress />}
        {this.props.bookings.isRejected && <PageError>Buchungen konnten nicht geladen werden.</PageError>}
        {this.props.bookings.isResolved && (
          <>
            Gesamtmenge: {bookings.reduce((totalAmount, booking) => totalAmount + booking.amount, 0)}
            <List dense>
              {bookings.map((booking, index, list) => (
                <ListItem key={booking.id} divider={index !== list.length - 1} alignItems="flex-start" dense>
                  <ListItemText
                    primary={
                      <>
                        <b>{booking.amount}x</b>
                        {today && (
                          <>
                            {" "}
                            um <StringToTime value={booking.date} />
                          </>
                        )}
                        {!today && (
                          <>
                            {" "}
                            am <StringToDateTime value={booking.date} />
                          </>
                        )}{" "}
                        über {booking.agent}
                      </>
                    }
                    secondary={`Bestell ID: ${booking.bestellId || "-"}`}
                  />
                </ListItem>
              ))}
              {bookings.length === 0 && (
                <ListItem alignItems="flex-start">
                  <ListItemText primary="Keine Buchungen gelistet." />
                </ListItem>
              )}
            </List>
          </>
        )}
      </>
    )
  }
}

const getBookingsPromise = (opts: {
  pharmacyId: string
  restrictedArticleId: string
  quotaTimespanInDays: number
}): BookingsPromise => {
  return AuthenticationService.fetch<BookingsPromise>(
    `${config.backend.url}/bookings/pharmacy/${opts.pharmacyId}/restrictedArticle/${opts.restrictedArticleId}?${
      opts.quotaTimespanInDays ? `days=${opts.quotaTimespanInDays}` : ""
    }`
  ).then((response) => response.body)
}

export default (props: Omit<Props, "bookings">): JSX.Element => (
  <Loads load={getBookingsPromise} defer>
    {(bookings) => <Bookings {...props} bookings={bookings} />}
  </Loads>
)
