import { Box, Button, TextField } from "@material-ui/core"
import { DateTime } from "luxon"
import React from "react"
import { Loads } from "react-loads"

import config from "../../../config"
import PageError from "../../../elements/PageError"
import PageProgress from "../../../elements/PageProgress"
import AuthenticationService from "../../authentication/authentication.service"
import { LoadRenderProps } from "../../types"
import { QuotaApiResponse } from "./types"

type QuotaPromise = Promise<QuotaApiResponse>

export interface Props {
  pharmacyId: string
  restrictedArticleId: string
  quota: LoadRenderProps<Unpromise<QuotaPromise>>
  updateQuota: LoadRenderProps<Unpromise<QuotaPromise>>
}

interface State {
  quota?: Unpromise<QuotaPromise>
}

export class Quotas extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      quota: undefined,
    }
  }
  componentDidMount(): void {
    this.props.quota.load({
      pharmacyId: this.props.pharmacyId,
      restrictedArticleId: this.props.restrictedArticleId,
    })
  }
  componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.pharmacyId !== this.props.pharmacyId ||
      prevProps.restrictedArticleId !== this.props.restrictedArticleId
    ) {
      this.props.quota.load({
        pharmacyId: this.props.pharmacyId,
        restrictedArticleId: this.props.restrictedArticleId,
      })
      this.setState({ quota: undefined })
    }
    if (prevProps.quota !== this.props.quota && this.props.quota.response) {
      this.setState({ quota: this.props.quota.response })
    }
  }
  handleQuotaChange =
    (key: keyof NonNullable<State["quota"]>) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value || ""
      this.setState((prevState) => {
        if (prevState.quota) {
          return {
            ...prevState,
            quota: { ...prevState.quota, [key]: value },
          }
        }
        return {}
      })
    }
  handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault()
    this.props.updateQuota.load({ quota: this.state.quota })
  }
  render(): JSX.Element {
    return (
      <>
        {this.props.quota.isPending && <PageProgress />}
        {this.props.quota.isRejected && <PageError>Kontingent konnten nicht geladen werden.</PageError>}
        {this.state.quota && (
          <form onSubmit={this.handleSubmit}>
            {this.props.updateQuota.isRejected && <Box color="error">Kontingent konnte nicht geändert werden.</Box>}
            {this.props.updateQuota.isPending ? (
              <PageProgress />
            ) : (
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Erwartete Nachfrage (Importiert)"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={this.state.quota.expectedDemand}
                  onChange={this.handleQuotaChange("expectedDemand")}
                />
                <TextField
                  margin="dense"
                  label="Lager (Importiert)"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={this.state.quota.stockAmount}
                  onChange={this.handleQuotaChange("stockAmount")}
                  disabled
                />
                <TextField
                  margin="dense"
                  label="Letzter Import"
                  type="datetime-local"
                  fullWidth
                  variant="outlined"
                  value={
                    this.state.quota.lastImport
                      ? DateTime.fromISO(this.state.quota.lastImport).toFormat("yyyy-MM-dd'T'HH:mm:ss")
                      : ""
                  }
                  onChange={this.handleQuotaChange("lastImport")}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button color="primary" type="submit" fullWidth>
                  Ändern
                </Button>
              </>
            )}
          </form>
        )}
      </>
    )
  }
}

const getQuotaPromise = (opts: { pharmacyId: string; restrictedArticleId: string }): QuotaPromise =>
  AuthenticationService.fetch<QuotaPromise>(
    `${config.backend.url}/quotas/pharmacy/${opts.pharmacyId}/restrictedArticle/${opts.restrictedArticleId}`
  ).then((response) => response.body)

const getUpdateQuotaPromise = (opts: { quota: Unpromise<QuotaPromise> }): QuotaPromise =>
  AuthenticationService.fetch<QuotaPromise>(`${config.backend.url}/quotas/${opts.quota.id}`, {
    method: "PUT",
    body: {
      ...opts.quota,
      id: undefined,
    },
  }).then((response) => response.body)

export default (props: Omit<Omit<Props, "quota">, "updateQuota">): JSX.Element => (
  <Loads load={getQuotaPromise} defer>
    {(quota) => (
      <Loads load={getUpdateQuotaPromise} defer>
        {(updateQuota) => <Quotas {...props} quota={quota} updateQuota={updateQuota} />}
      </Loads>
    )}
  </Loads>
)
