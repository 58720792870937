import { Fab, Zoom } from "@material-ui/core"
import { FabProps } from "@material-ui/core/Fab"
import { Add } from "@material-ui/icons"
import { styled } from "@material-ui/styles"
import React from "react"

interface Props extends Omit<FabProps, "children"> {
  icon: "add"
  children?: FabProps["children"]
}

const FloatingActionButton: React.FC<Props> = ({ icon, children, ...props }) => {
  return (
    <Zoom in>
      <FloatingButton {...props}>
        {icon === "add" && <Add />}
        {children}
      </FloatingButton>
    </Zoom>
  )
}

const FloatingButton = styled(Fab)({
  position: "fixed",
  bottom: "12px",
  right: "12px",
})

export default FloatingActionButton
