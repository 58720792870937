import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Select,
} from "@material-ui/core"
import { GetApp as GetAppIcon } from "@material-ui/icons"
import contentDisposition from "content-disposition"
import * as fileSaver from "file-saver"
import React from "react"

import config from "../../config"
import Headline from "../../elements/Headline"
import PageContainer from "../../elements/PageContainer"
import AuthenticationService from "../authentication/authentication.service"

interface Props {}

interface State {
  bookingsMonth: string
  bookingsYear: string
  aggregatedBookingsMonth: string
  aggregatedBookingsYear: string
}

export class Exports extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      bookingsMonth: `${new Date().getMonth() + 1}`,
      bookingsYear: `${new Date().getFullYear()}`,
      aggregatedBookingsMonth: `${new Date().getMonth() + 1}`,
      aggregatedBookingsYear: `${new Date().getFullYear()}`,
    }
  }

  download = (path: string, fallbackFilename: string): void => {
    AuthenticationService.fetchBlob(`${config.backend.url}/${path}`).then(
      (response) => {
        const filename = contentDisposition.parse(
          response.headers.get("content-disposition") || `attachment; filename="${fallbackFilename}"`
        ).parameters["filename"]
        fileSaver.saveAs(response.blob, filename)
      },
      (error) => {
        alert(error)
      }
    )
  }
  downloadActivePharmacies = (): void => {
    this.download("exports/active-pharmacies", "active-pharmacies.csv")
  }
  downloadBookings = (): void => {
    this.download(`exports/bookings/${this.state.bookingsYear}/${this.state.bookingsMonth}`, "buchungen.csv")
  }
  downloadAggregatedBookings = (): void => {
    this.download(
      `exports/aggregated-bookings/${this.state.aggregatedBookingsYear}/${this.state.aggregatedBookingsMonth}`,
      "aggregierte-buchungen.csv"
    )
  }

  handleSelectChange =
    (key: string) =>
    (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
      const value = (event.target.value as string) || ""
      this.setState((prevState) => ({ ...prevState, [key]: value }))
    }

  render(): JSX.Element {
    const monthOptions = [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "July",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ].map((name, index) => (
      <option key={name} value={index + 1}>
        {name}
      </option>
    ))
    const yearOptions = [
      new Date().getFullYear() - 5,
      new Date().getFullYear() - 4,
      new Date().getFullYear() - 3,
      new Date().getFullYear() - 2,
      new Date().getFullYear() - 1,
      new Date().getFullYear(),
    ].map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ))
    return (
      <PageContainer>
        <Headline variant="h4" component="h1">
          Exporte
        </Headline>
        <Paper>
          <List>
            <ListItem alignItems="flex-start">
              {/* <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon> */}
              <ListItemText primary="Aktive teilnehmende Apotheken" />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={this.downloadActivePharmacies}>
                  <GetAppIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText primary="Bestellungen" />
              <ListItemSecondaryAction>
                <Select native value={this.state.bookingsMonth} onChange={this.handleSelectChange("bookingsMonth")}>
                  {monthOptions}
                </Select>
                <Select native value={this.state.bookingsYear} onChange={this.handleSelectChange("bookingsYear")}>
                  {yearOptions}
                </Select>
                <IconButton edge="end" onClick={this.downloadBookings}>
                  <GetAppIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText primary="Abrechnungsrelevante Bestellungen" />
              <ListItemSecondaryAction>
                <Select
                  native
                  value={this.state.aggregatedBookingsMonth}
                  onChange={this.handleSelectChange("aggregatedBookingsMonth")}
                >
                  {monthOptions}
                </Select>
                <Select
                  native
                  value={this.state.aggregatedBookingsYear}
                  onChange={this.handleSelectChange("aggregatedBookingsYear")}
                >
                  {yearOptions}
                </Select>
                <IconButton edge="end" onClick={this.downloadAggregatedBookings}>
                  <GetAppIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
      </PageContainer>
    )
  }
}

export default Exports
