import {
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
} from "@material-ui/core"
import { DateTime } from "luxon"
import React from "react"
import { Loads } from "react-loads"

import config from "../../config"
import Headline from "../../elements/Headline"
import PageContainer from "../../elements/PageContainer"
import PageError from "../../elements/PageError"
import PageLoading from "../../elements/PageLoading"
import Strikethrough from "../../elements/Strikethrough"
import StringToDate from "../../elements/StringToDate"
import AuthenticationService from "../authentication/authentication.service"
// import { RestrictedArticlesApiResponse } from "../RestrictedArticles/types"
import { LoadRenderProps } from "../types"
import AddPharmacy from "./AddPharmacy"
import DeletePharmacy from "./DeletePharmacy"
import EditPharmacy from "./EditPharmacy"
import { PharmaciesApiResponse, Pharmacy } from "./types"

type PharmacyPromise = Promise<PharmaciesApiResponse>

// type RestrictedArticlesPromise = Promise<RestrictedArticlesApiResponse>

export interface Props {
  pharmacies: LoadRenderProps<Unpromise<PharmacyPromise>>
}

interface State {
  activeOnly: boolean
}

export class Pharmacies extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      activeOnly: true,
    }
  }

  private onActiveOnlyChanged = (event: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ activeOnly: event.target.checked })

  render(): JSX.Element | string {
    const renderTitle = (pharmacy: Pharmacy): JSX.Element | string => {
      const text = `${pharmacy.idf} - ${pharmacy.name}`
      if (isPharmacyInactive(pharmacy)) {
        return <Strikethrough>{text}</Strikethrough>
      }
      return text
    }
    return (
      <PageContainer>
        <Headline variant="h4" component="h1">
          Teilnehmende Apotheken
        </Headline>
        <FormControlLabel
          control={<Switch checked={this.state.activeOnly} onChange={this.onActiveOnlyChanged} />}
          label="Nur aktive"
        />
        <Paper>
          {this.props.pharmacies.isPending && <PageLoading />}
          {this.props.pharmacies.isRejected && (
            <PageError>Teilnehmende Apotheken konnten nicht geladen werden.</PageError>
          )}
          {this.props.pharmacies.isResolved && (
            <>
              <List>
                {this.props.pharmacies.response &&
                  this.props.pharmacies.response.pharmacies
                    .filter((pharmacy) => !this.state.activeOnly || !isPharmacyInactive(pharmacy))
                    .map((pharmacy, index, list) => (
                      <ListItem key={pharmacy.id} divider={index !== list.length - 1} alignItems="flex-start">
                        <ListItemText
                          primary={renderTitle(pharmacy)}
                          secondary={
                            <>
                              <StringToDate value={pharmacy.activeFrom} /> -{" "}
                              <StringToDate value={pharmacy.activeTo} fallback="Unbegrenzt" />
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <EditPharmacy pharmacy={pharmacy} onSuccess={this.props.pharmacies.load} />
                          <DeletePharmacy
                            id={pharmacy.id}
                            name={pharmacy.name}
                            onSuccess={this.props.pharmacies.load}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                {this.props.pharmacies.response && this.props.pharmacies.response.pharmacies.length === 0 && (
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Keine Apotheken gelistet." />
                  </ListItem>
                )}
              </List>
              <AddPharmacy onSuccess={this.props.pharmacies.load} />
            </>
          )}
        </Paper>
      </PageContainer>
    )
  }
}

function isPharmacyInactive(pharmacy: Pharmacy): boolean {
  return !!pharmacy.activeTo && DateTime.fromISO(pharmacy.activeTo).toMillis() < DateTime.local().toMillis()
}

const getPharmacyPromise = (): PharmacyPromise =>
  AuthenticationService.fetch<PharmaciesApiResponse>(`${config.backend.url}/pharmacies`).then(
    (response) => response.body
  )

export default (): JSX.Element => (
  <Loads load={getPharmacyPromise}>{(pharmacies) => <Pharmacies pharmacies={pharmacies} />}</Loads>
)
