import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { DateTime } from "luxon"
import React, { FormEvent } from "react"
import { Loads } from "react-loads"

import config from "../../../config"
import FloatingActionButton from "../../../elements/FloatingActionButton"
import PageProgress from "../../../elements/PageProgress"
import AuthenticationService from "../../authentication/authentication.service"
import { LoadRenderProps } from "../../types"
import { AgentTypes } from "../types"

type DataPromise = Promise<void>

export interface Props {
  data: LoadRenderProps<Unpromise<DataPromise>>
  onSuccess: () => void
}

interface State {
  isDialogOpen: boolean
  pzn: number
  ipzns: {
    agent: AgentTypes
    pzn: number
  }[]
  name: string
  bufferPercentage: number
  minBufferAmount: number
  maxBufferAmount: number
  minExpectedDemand: number
  maxExpectedDemand: number
  activeFrom: DateTime
  activeTo: DateTime | null
}

export class AddRestrictedArticle extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isDialogOpen: false,
      pzn: 0,
      ipzns: [],
      name: "",
      bufferPercentage: 0,
      minBufferAmount: 0,
      maxBufferAmount: 0,
      minExpectedDemand: 0,
      maxExpectedDemand: 0,
      activeFrom: DateTime.local(),
      activeTo: null,
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.data.isResolved !== this.props.data.isResolved && this.props.data.isResolved) {
      this.props.onSuccess()
      this.setState({
        pzn: 0,
        ipzns: [],
        name: "",
        bufferPercentage: 0,
        minBufferAmount: 0,
        maxBufferAmount: 0,
        minExpectedDemand: 0,
        maxExpectedDemand: 0,
        activeFrom: DateTime.local(),
        activeTo: null,
        isDialogOpen: false,
      })
      this.props.data.reset()
    }
  }

  openDialog = (): void => this.setState({ isDialogOpen: true })
  closeDialog = (): void => (!this.props.data.isPending && this.setState({ isDialogOpen: false })) || undefined

  handleChange =
    (key: keyof State) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value || ""
      this.setState((prevState) => ({ ...prevState, [key]: value }))
    }

  handleIPznChange =
    (key: keyof State["ipzns"][0], index: number) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value || ""
      this.setState((prevState) => ({
        ...prevState,
        ipzns: prevState.ipzns.map((ipzn, ipznIndex) => {
          if (ipznIndex !== index) return ipzn
          return {
            ...ipzn,
            [key]: value,
          }
        }),
      }))
    }

  handleIPznSelectChange =
    (key: keyof State["ipzns"][0], index: number) =>
    (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
      const value = event.target.value || ""
      this.setState((prevState) => ({
        ...prevState,
        ipzns: prevState.ipzns.map((ipzn, ipznIndex) => {
          if (ipznIndex !== index) return ipzn
          return {
            ...ipzn,
            [key]: value,
          }
        }),
      }))
    }

  handleNumberChange =
    (key: keyof State) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = Number(event.target.value || "")
      this.setState((prevState) => ({ ...prevState, [key]: value }))
    }

  handleDateChange =
    (key: keyof State) =>
    (date: MaterialUiPickersDate | null): void => {
      this.setState((prevState) => ({ ...prevState, [key]: date }))
    }

  addNewIPzn = (): void => {
    this.setState((prevState) => ({
      ...prevState,
      ipzns: [...prevState.ipzns, { agent: AgentTypes.AEP, pzn: 0 }],
    }))
  }

  removeIPzn = (index: number) => (): void => {
    this.setState((prevState) => ({
      ...prevState,
      ipzns: prevState.ipzns.filter((ipzn, ipznIndex) => ipznIndex !== index),
    }))
  }

  handleSubmit = (event: FormEvent): void => {
    event.preventDefault()
    this.props.data.load({
      pzn: this.state.pzn,
      ipzns: this.state.ipzns,
      name: this.state.name,
      bufferPercentage: this.state.bufferPercentage / 100,
      minBufferAmount: this.state.minBufferAmount,
      maxBufferAmount: this.state.maxBufferAmount,
      minExpectedDemand: this.state.minExpectedDemand,
      maxExpectedDemand: this.state.maxExpectedDemand,
      activeFrom: this.state.activeFrom,
      activeTo: this.state.activeTo,
    })
  }

  render(): JSX.Element {
    return (
      <>
        <FloatingActionButton color="primary" icon="add" onClick={this.openDialog} />
        <Dialog onClose={this.closeDialog} open={this.state.isDialogOpen}>
          <form onSubmit={this.handleSubmit}>
            <DialogTitle>Kontingentierten Artikel hinzufügen</DialogTitle>
            <DialogContent>
              {this.props.data.isRejected && (
                <DialogContentText color="error">Artikel konnte nicht hinzugefügt werden.</DialogContentText>
              )}
              {this.props.data.isPending ? (
                <PageProgress />
              ) : (
                <>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.name}
                    onChange={this.handleChange("name")}
                    required
                  />
                  <TextField
                    margin="dense"
                    label="PZN"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={this.state.pzn}
                    onChange={this.handleChange("pzn")}
                    required
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    margin="dense"
                    label="Aktiv ab"
                    fullWidth
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="dd.MM.yyyy"
                    value={this.state.activeFrom}
                    onChange={this.handleDateChange("activeFrom")}
                    required
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    margin="dense"
                    label="Aktiv bis"
                    fullWidth
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="dd.MM.yyyy"
                    value={this.state.activeTo}
                    onChange={this.handleDateChange("activeTo")}
                  />
                  <TextField
                    margin="dense"
                    label="Puffer (in %)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={this.state.bufferPercentage}
                    onChange={this.handleNumberChange("bufferPercentage")}
                    required
                  />
                  <TextField
                    margin="dense"
                    label="Untergrenze Puffer"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={this.state.minBufferAmount}
                    onChange={this.handleNumberChange("minBufferAmount")}
                    required
                  />
                  <TextField
                    margin="dense"
                    label="Obergrenze Puffer"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={this.state.maxBufferAmount}
                    onChange={this.handleNumberChange("maxBufferAmount")}
                    required
                  />
                  <TextField
                    margin="dense"
                    label="Untergrenze erwartete Nachfrage"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={this.state.minExpectedDemand}
                    onChange={this.handleNumberChange("minExpectedDemand")}
                    required
                  />
                  <TextField
                    margin="dense"
                    label="Obergrenze erwartete Nachfrage"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={this.state.maxExpectedDemand}
                    onChange={this.handleNumberChange("maxExpectedDemand")}
                    required
                  />
                  <Typography variant="subtitle1">Interne PZNs</Typography>
                  {this.state.ipzns.map((ipzn, index) => (
                    <Grid container spacing={1} justify="space-around" alignItems="center" key={index}>
                      <Grid item xs="auto">
                        <FormControl variant="outlined" fullWidth margin="dense">
                          <Select
                            native
                            value={ipzn.agent}
                            onChange={this.handleIPznSelectChange("agent", index)}
                            fullWidth
                          >
                            {Object.values(AgentTypes).map((type) => (
                              <option value={type} key={type}>
                                {type}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="iPzn"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={ipzn.pzn ?? ""}
                          onChange={this.handleIPznChange("pzn", index)}
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <Button variant="text" onClick={this.removeIPzn(index)}>
                          Entfernen
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                  <Button variant="text" color="primary" onClick={this.addNewIPzn}>
                    iPzn hinzufügen
                  </Button>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.closeDialog} disabled={this.props.data.isPending}>
                Schließen
              </Button>
              <Button color="primary" onClick={this.handleSubmit} disabled={this.props.data.isPending} type="submit">
                Hinzufügen
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    )
  }
}

const getPromise = (opts: {
  pzn: number
  ipzns: {
    agent: AgentTypes
    pzn: number
  }[]
  name: string
  bufferPercentage: number
  minBufferAmount: number
  maxBufferAmount: number
  minExpectedDemand: number
  maxExpectedDemand: number
  activeFrom: DateTime
  activeTo: DateTime | null
}): DataPromise =>
  AuthenticationService.fetch<void>(`${config.backend.url}/restricted-articles`, {
    method: "POST",
    body: {
      pzn: opts.pzn,
      ipzns: opts.ipzns,
      name: opts.name,
      bufferPercentage: opts.bufferPercentage,
      minBufferAmount: opts.minBufferAmount,
      maxBufferAmount: opts.maxBufferAmount,
      minExpectedDemand: opts.minExpectedDemand,
      maxExpectedDemand: opts.maxExpectedDemand,
      activeFrom: opts.activeFrom.toISODate(),
      activeTo: opts.activeTo ? opts.activeTo.toISODate() : null,
    },
  }).then((response) => response.body)

export default (props: Omit<Props, "data">): JSX.Element => (
  <Loads defer load={getPromise}>
    {(data) => <AddRestrictedArticle {...props} data={data} />}
  </Loads>
)
