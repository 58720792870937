import LuxonUtils from "@date-io/luxon"
import { CssBaseline } from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ThemeProvider } from "@material-ui/styles"
import React from "react"
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom"

import withAuthentication from "../features/authentication/withAuthentication"
import Contingents from "../features/Contingents/Contingents"
import Exports from "../features/Exports/Exports"
import Navigation from "../features/Navigation"
import NotFound from "../features/NotFound"
import Pharmacies from "../features/Pharmacies/Pharmacies"
import RestrictedArticles from "../features/RestrictedArticles/RestrictedArticles"
import urls from "../urls"
import theme from "./theme"

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <CssBaseline />
        <Router>
          <Navigation />
          <Switch>
            <Redirect exact path="/" to={urls.restrictedArticles()} />
            <Route path={urls.restrictedArticles()} component={RestrictedArticles} />
            <Route path={urls.pharmacies()} component={Pharmacies} />
            <Route path={urls.contingents()} component={Contingents} />
            <Route path={urls.exports()} component={Exports} />
            <Route render={() => <NotFound />} />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default withAuthentication(App)
