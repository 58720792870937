import { Button, TextField } from "@material-ui/core"
import React from "react"
import { Loads } from "react-loads"

import config from "../../../config"
import PageError from "../../../elements/PageError"
import PageProgress from "../../../elements/PageProgress"
import AuthenticationService from "../../authentication/authentication.service"
import { RestrictedArticleApiResponse } from "../../RestrictedArticles/types"
import { LoadRenderProps } from "../../types"

type RestrictedArticlePromise = Promise<RestrictedArticleApiResponse>

export interface Props {
  restrictedArticleId: string
  restrictedArticle: LoadRenderProps<Unpromise<RestrictedArticlePromise>>
}

export class Buffer extends React.PureComponent<Props> {
  componentDidMount(): void {
    this.props.restrictedArticle.load({
      restrictedArticleId: this.props.restrictedArticleId,
    })
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.restrictedArticleId !== this.props.restrictedArticleId) {
      this.props.restrictedArticle.load({
        restrictedArticleId: this.props.restrictedArticleId,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {}

  render(): JSX.Element {
    return (
      <>
        {this.props.restrictedArticle.isPending && <PageProgress />}
        {this.props.restrictedArticle.isRejected && <PageError>Puffer konnte nicht geladen werden.</PageError>}
        {this.props.restrictedArticle.isResolved && this.props.restrictedArticle.response && (
          <form>
            <TextField
              margin="dense"
              label="Puffer (in %)"
              type="text"
              fullWidth
              variant="outlined"
              value={this.props.restrictedArticle.response.bufferPercentage * 100}
              onChange={this.handleChange}
              disabled
            />
            <TextField
              margin="dense"
              label="Untergrenze Puffer"
              type="text"
              fullWidth
              variant="outlined"
              value={this.props.restrictedArticle.response.minBufferAmount}
              onChange={this.handleChange}
              disabled
            />
            <TextField
              margin="dense"
              label="Obergrenze Puffer"
              type="text"
              fullWidth
              variant="outlined"
              value={this.props.restrictedArticle.response.maxBufferAmount}
              onChange={this.handleChange}
              disabled
            />
            <TextField
              margin="dense"
              label="Untergrenze erwartete Nachfrage"
              type="text"
              fullWidth
              variant="outlined"
              value={this.props.restrictedArticle.response.minExpectedDemand}
              disabled
            />
            <TextField
              margin="dense"
              label="Obergrenze erwartete Nachfrage"
              type="text"
              fullWidth
              variant="outlined"
              value={this.props.restrictedArticle.response.maxExpectedDemand}
              disabled
            />
            <Button color="primary" type="submit" fullWidth disabled>
              Bitte im Menüpunkt "Kontingentierte Artikel" ändern
            </Button>
          </form>
        )}
      </>
    )
  }
}

const getRestrictedArticlePromise = (opts: { restrictedArticleId: string }): RestrictedArticlePromise =>
  AuthenticationService.fetch<RestrictedArticleApiResponse>(
    `${config.backend.url}/restricted-articles/${opts.restrictedArticleId}`
  ).then((response) => response.body)

export default (props: Omit<Props, "restrictedArticle">): JSX.Element => (
  <Loads load={getRestrictedArticlePromise} defer>
    {(restrictedArticle) => <Buffer {...props} restrictedArticle={restrictedArticle} />}
  </Loads>
)
