import { createTheme, responsiveFontSizes } from "@material-ui/core/styles"

export default responsiveFontSizes(
  createTheme({
    typography: {
      htmlFontSize: 16,
      fontSize: 14,
      fontFamily: ['"Exo 2"', "Helvetica", '"-apple-system"', "Arial", '"sans-serif"'].join(","),
    },
  })
)
