export enum AgentTypes {
  AEP = "AEP",
  SANACORP = "SANACORP",
  PHOENIX = "PHOENIX",
}

export interface Agent {
  id: string
  agent: AgentTypes
  username: string
}

export interface Pharmacy {
  id: string
  idf: string
  name: string
  activeFrom: string
  activeTo?: string | null
  dailyQuotasPerTimespan: number
  quotaTimespanInDays: number
  agents: Agent[]
}

export interface PharmaciesApiResponse {
  pharmacies: Pharmacy[]
}
