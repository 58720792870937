import {
  AppBar,
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@material-ui/core"
import {
  DonutLarge as DonutLargeIcon,
  GetApp as GetAppIcon,
  Menu as MenuIcon,
  RemoveShoppingCart as RemoveShoppingCartIcon,
  Store as StoreIcon,
} from "@material-ui/icons"
import { styled } from "@material-ui/styles"
import React from "react"
import { RouteComponentProps, withRouter } from "react-router"

import urls from "../../urls"
import AuthenticationService from "../authentication/authentication.service"
import logo from "./logo.png"

interface Props extends RouteComponentProps {}

export const Navigation: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isDrawerOpen, setDrawerOpen] = React.useState(false)

  const openMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = (): void => {
    setAnchorEl(null)
  }

  const toggleDrawer = (): void => {
    setDrawerOpen(!isDrawerOpen)
  }

  const logout = (): void => {
    AuthenticationService.logout()
  }

  const goTo = (path: string) => () => {
    if (props.location.pathname !== path) {
      props.history.push(path)
    }
  }

  return (
    <StyledAppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="Menu" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer open={isDrawerOpen} onOpen={toggleDrawer} onClose={toggleDrawer}>
          <MinWidthDrawer onClick={toggleDrawer}>
            <ToolbarSpacer>
              <LogoImg src={logo} alt="kjur logo" />
              <Typography variant="h6" color="inherit">
                Admin Tool
              </Typography>
            </ToolbarSpacer>
            <Divider />
            <List>
              <ListItem button onClick={goTo(urls.restrictedArticles())}>
                <ListItemIcon>
                  <RemoveShoppingCartIcon />
                </ListItemIcon>
                <ListItemText>Kontingentierte Artikel</ListItemText>
              </ListItem>
              <ListItem button onClick={goTo(urls.pharmacies())}>
                <ListItemIcon>
                  <StoreIcon />
                </ListItemIcon>
                <ListItemText>Teilnehmende Apotheken</ListItemText>
              </ListItem>
              <ListItem button onClick={goTo(urls.contingents())}>
                <ListItemIcon>
                  <DonutLargeIcon />
                </ListItemIcon>
                <ListItemText>Kontingente</ListItemText>
              </ListItem>
              <ListItem button onClick={goTo(urls.exports())}>
                <ListItemIcon>
                  <GetAppIcon />
                </ListItemIcon>
                <ListItemText>Exporte</ListItemText>
              </ListItem>
            </List>
          </MinWidthDrawer>
        </SwipeableDrawer>
        <GrowingTypography variant="h6" color="inherit">
          kjur Admin Tool
        </GrowingTypography>
        {AuthenticationService.isLoggedIn() && (
          <>
            <IconButton
              aria-label="Account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="false"
              onClick={openMenu}
              color="inherit"
            >
              {generateAvatar(AuthenticationService.getUser().name)}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
            >
              <MenuItem disabled divider>
                {AuthenticationService.getUser().name}
              </MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </StyledAppBar>
  )
}

const StyledAppBar = styled(AppBar)({
  marginBottom: "12px",
})

const GrowingTypography = styled(Typography)({
  flex: 1,
})

const MinWidthDrawer = styled((props) => <div {...props} />)({
  minWidth: 250,
})

const LogoImg = styled((props) => <img alt="logo" {...props} />)({
  height: "40px",
  marginBottom: "16px",
})

const ToolbarSpacer = styled((props) => <div {...props} />)({
  height: "64px",
  display: "flex",
  alignItems: "center",
  padding: "8px 16px",
})

const generateAvatar = (name: string): JSX.Element => {
  const letters = name
    .split(" ")
    .map((word) => word[0])
    .join("")
  const stringToColour = (str: string): string => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    let colour = "#"
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      colour += ("00" + value.toString(16)).substr(-2)
    }
    return colour
  }
  const StyledAvatar = styled(Avatar)({
    background: stringToColour(name),
    color: "#fff",
  })
  return <StyledAvatar>{letters}</StyledAvatar>
}

export default withRouter(Navigation)
