import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { DateTime } from "luxon"
import React, { FormEvent } from "react"
import { Loads } from "react-loads"

import config from "../../../config"
import FloatingActionButton from "../../../elements/FloatingActionButton"
import PageProgress from "../../../elements/PageProgress"
import AuthenticationService from "../../authentication/authentication.service"
import { LoadRenderProps } from "../../types"
import { AgentTypes } from "../types"

type DataPromise = Promise<void>

export interface Props {
  data: LoadRenderProps<Unpromise<DataPromise>>
  onSuccess: () => void
}

interface State {
  isDialogOpen: boolean
  idf: string
  name: string
  activeFrom: DateTime
  activeTo: DateTime | null
  dailyQuotasPerTimespan: number
  quotaTimespanInDays: number
  agents: {
    agent: AgentTypes
    username: string
  }[]
}

export class AddPharmacy extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isDialogOpen: false,
      idf: "",
      name: "",
      activeFrom: DateTime.local(),
      activeTo: null,
      agents: [],
      dailyQuotasPerTimespan: 1,
      quotaTimespanInDays: 1,
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.data.isResolved !== this.props.data.isResolved && this.props.data.isResolved) {
      this.props.onSuccess()
      this.setState({
        idf: "",
        name: "",
        activeFrom: DateTime.local(),
        activeTo: null,
        isDialogOpen: false,
        agents: [],
      })
      this.props.data.reset()
    }
  }

  openDialog = (): void => this.setState({ isDialogOpen: true })
  closeDialog = (): void => (!this.props.data.isPending && this.setState({ isDialogOpen: false })) || undefined

  handleChange =
    (key: keyof State) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value || ""
      this.setState((prevState) => ({ ...prevState, [key]: value }))
    }

  handleAgentChange =
    (key: keyof State["agents"][0], index: number) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value || ""
      this.setState((prevState) => ({
        ...prevState,
        agents: prevState.agents.map((agent, agentIndex) => {
          if (agentIndex !== index) return agent
          return {
            ...agent,
            [key]: value,
          }
        }),
      }))
    }

  handleAgentSelectChange =
    (key: keyof State["agents"][0], index: number) =>
    (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
      const value = event.target.value || ""
      this.setState((prevState) => ({
        ...prevState,
        agents: prevState.agents.map((agent, agentIndex) => {
          if (agentIndex !== index) return agent
          return {
            ...agent,
            [key]: value,
          }
        }),
      }))
    }

  handleDateChange =
    (key: keyof State) =>
    (date: MaterialUiPickersDate | null): void => {
      this.setState((prevState) => ({ ...prevState, [key]: date }))
    }

  addNewAgent = (): void => {
    this.setState((prevState) => ({
      ...prevState,
      agents: [...prevState.agents, { agent: AgentTypes.AEP, username: "" }],
    }))
  }

  removeAgent = (index: number) => (): void => {
    this.setState((prevState) => ({
      ...prevState,
      agents: prevState.agents.filter((agent, agentIndex) => agentIndex !== index),
    }))
  }

  handleSubmit = (event: FormEvent): void => {
    event.preventDefault()
    this.props.data.load({
      idf: this.state.idf,
      name: this.state.name,
      activeFrom: this.state.activeFrom,
      activeTo: this.state.activeTo,
      agents: this.state.agents,
      dailyQuotasPerTimespan: this.state.dailyQuotasPerTimespan,
      quotaTimespanInDays: this.state.quotaTimespanInDays,
    })
  }

  render(): JSX.Element {
    return (
      <>
        <FloatingActionButton color="primary" icon="add" onClick={this.openDialog} />
        <Dialog onClose={this.closeDialog} open={this.state.isDialogOpen}>
          <form onSubmit={this.handleSubmit}>
            <DialogTitle>Apotheke hinzufügen</DialogTitle>
            <DialogContent>
              {this.props.data.isRejected && (
                <DialogContentText color="error">Apotheke konnte nicht hinzugefügt werden.</DialogContentText>
              )}
              {this.props.data.isPending ? (
                <PageProgress />
              ) : (
                <>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Name*"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.name}
                    onChange={this.handleChange("name")}
                  />
                  <TextField
                    margin="dense"
                    label="IDF*"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.idf}
                    onChange={this.handleChange("idf")}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    margin="dense"
                    label="Aktiv ab"
                    fullWidth
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="dd.MM.yyyy"
                    value={this.state.activeFrom}
                    onChange={this.handleDateChange("activeFrom")}
                    required
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    margin="dense"
                    label="Aktiv bis"
                    fullWidth
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="dd.MM.yyyy"
                    value={this.state.activeTo}
                    onChange={this.handleDateChange("activeTo")}
                  />
                  <TextField
                    margin="dense"
                    label="Tage der berücksichtigten Bestellungen"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputProps={{ inputProps: { min: 1 } }}
                    value={this.state.quotaTimespanInDays}
                    onChange={this.handleChange("quotaTimespanInDays")}
                  />
                  <TextField
                    margin="dense"
                    label="Anzahl gewährte Tageskontingente"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputProps={{ inputProps: { min: 1 } }}
                    value={this.state.dailyQuotasPerTimespan}
                    onChange={this.handleChange("dailyQuotasPerTimespan")}
                  />
                  <Typography variant="subtitle1">Kundenkennungen</Typography>
                  {this.state.agents.map((agent, index) => (
                    <Grid container spacing={1} justify="space-around" alignItems="center" key={index}>
                      <Grid item xs="auto">
                        <FormControl variant="outlined" fullWidth margin="dense">
                          <Select
                            native
                            value={agent.agent}
                            onChange={this.handleAgentSelectChange("agent", index)}
                            fullWidth
                          >
                            {Object.values(AgentTypes).map((type) => (
                              <option value={type} key={type}>
                                {type}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Kundenkennung"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={agent.username ?? ""}
                          onChange={this.handleAgentChange("username", index)}
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <Button variant="text" onClick={this.removeAgent(index)}>
                          Entfernen
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                  <Button variant="text" color="primary" onClick={this.addNewAgent}>
                    Kundenkennung hinzufügen
                  </Button>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.closeDialog} disabled={this.props.data.isPending}>
                Schließen
              </Button>
              <Button color="primary" disabled={this.props.data.isPending} type="submit">
                Hinzufügen
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    )
  }
}

const getPromise = (opts: {
  idf: string
  name: string
  activeFrom: DateTime
  activeTo: DateTime | null
  agents: {
    agent: AgentTypes
    username: string
  }[]
  dailyQuotasPerTimespan: number
  quotaTimespanInDays: number
}): DataPromise =>
  AuthenticationService.fetch<void>(`${config.backend.url}/pharmacies`, {
    method: "POST",
    body: {
      idf: opts.idf,
      name: opts.name,
      activeFrom: opts.activeFrom.toISODate(),
      activeTo: opts.activeTo ? opts.activeTo.toISODate() : null,
      agents: opts.agents,
      dailyQuotasPerTimespan: opts.dailyQuotasPerTimespan,
      quotaTimespanInDays: opts.quotaTimespanInDays,
    },
  }).then((response) => response.body)

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: Omit<Props, "data">): JSX.Element => (
  <Loads defer load={getPromise}>
    {(data) => <AddPharmacy {...props} data={data} />}
  </Loads>
)
