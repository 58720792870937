import { Options, SalteAuth } from "@salte-auth/salte-auth"

import config from "../../config"

class AuthenticationService {
  private authenticationContext: SalteAuth

  constructor(config: Options) {
    this.authenticationContext = new SalteAuth(config)
    this.authenticationContext.$provider.authorizeEndpoint = (config) => `${config.providerUrl}/oauth2/v2.0/authorize`
  }

  isLoggedIn = (): boolean => {
    return !!this.authenticationContext.profile.userInfo && !this.authenticationContext.profile.idTokenExpired
  }
  getUser = (): {
    name: string
    userName: string
    email: string
  } => {
    if (!this.isLoggedIn()) {
      this.login()
      return {
        name: "Unknown",
        userName: "Unknown",
        email: "",
      }
    }
    const userInfo = this.authenticationContext.profile.userInfo || { name: "-", preferred_username: "-", email: "-" }
    return {
      name: userInfo.name,
      userName: userInfo.preferred_username,
      email: userInfo.email,
    }
  }
  login = (): Promise<void> => {
    return this.authenticationContext.loginWithRedirect().then((data) => {
      console.log("logged in", data)
      return undefined
    })
  }
  logout = (): void => {
    this.authenticationContext.logoutWithRedirect()
  }
  fetch = <ResponseType>(
    url: string,
    options: {
      method?: string
      headers?: object
      body?: object
    } = {}
  ): Promise<{
    status: number
    headers: {}
    body: ResponseType
  }> => {
    if (!this.isLoggedIn()) this.login()
    console.log("fetch", this.authenticationContext.profile.$idToken, this.authenticationContext.profile.idTokenExpired)
    return fetch(url, {
      ...options,
      body: options.body ? JSON.stringify(options.body) : undefined,
      headers: {
        ...options.headers,
        Authorization: `${this.authenticationContext.profile.$tokenType || "Bearer"} ${
          this.authenticationContext.profile.$idToken
        }`,
        Accept: "application/json",
        "Content-Type": options.body ? "application/json" : "text/plain",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          this.login()
          throw new Error("Unauthenticated")
        }
        if (response.status >= 300) {
          throw new Error("Bad status code")
        }
        return response
      })
      .then((response) => {
        const contentType = response.headers.get("content-type")
        if (contentType && contentType.includes("application/json")) {
          return response.json().then((body) => ({
            status: response.status,
            headers: response.headers,
            body: body as ResponseType,
          }))
        } else {
          return Promise.resolve({
            status: response.status,
            headers: response.headers,
            body: {} as ResponseType,
          })
        }
      })
  }
  fetchBlob = (
    url: string,
    options: {
      headers?: object
    } = {}
  ): Promise<{
    status: number
    headers: Headers
    blob: Blob
  }> => {
    if (!this.isLoggedIn()) this.login()
    console.log(
      "fetchBlob",
      this.authenticationContext.profile.$idToken,
      this.authenticationContext.profile.idTokenExpired
    )
    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `${this.authenticationContext.profile.$tokenType || "Bearer"} ${
          this.authenticationContext.profile.$idToken
        }`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          this.login()
          throw new Error("Unauthenticated")
        }
        if (response.status >= 300) {
          throw new Error("Bad status code")
        }
        return response
      })
      .then((response) => {
        return response.blob().then((blob) => ({
          status: response.status,
          headers: response.headers,
          blob,
        }))
      })
  }
}

export default new AuthenticationService(config.oauth2)
