import { Box, LinearProgress } from "@material-ui/core"
import React from "react"

const PageLoading: React.FC = () => {
  return (
    <Box padding="12px" width="100%">
      <LinearProgress />
    </Box>
  )
}

export default PageLoading
