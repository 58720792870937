import { Box } from "@material-ui/core"
import React from "react"

const PageError: React.FC = (props) => {
  return (
    <Box padding="12px" width="100%">
      {props.children}
    </Box>
  )
}

export default PageError
