import { DialogContentText, IconButton } from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import React from "react"
import { Loads } from "react-loads"

import config from "../../../config"
import ConfirmDialog from "../../../elements/ConfirmDialog"
import PageProgress from "../../../elements/PageProgress"
import AuthenticationService from "../../authentication/authentication.service"
import { LoadRenderProps } from "../../types"

type DataPromise = Promise<void>

interface Props {
  data: LoadRenderProps<Unpromise<DataPromise>>
  id: string
  pzn: string
  onSuccess: () => void
}

export class DeleteRestrictedArticle extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props): void {
    if (prevProps.data.isResolved !== this.props.data.isResolved && this.props.data.isResolved) {
      this.props.onSuccess()
    }
  }

  renderTrigger = (openConfirmModal: () => void): JSX.Element => {
    return (
      <IconButton color="secondary" edge="end" onClick={openConfirmModal}>
        <DeleteIcon />
      </IconButton>
    )
  }

  render(): JSX.Element {
    return (
      <>
        <ConfirmDialog
          title={`${this.props.pzn} wirklich löschen?`}
          submitButtonText="Löschen"
          id={this.props.pzn}
          onConfirm={this.props.data.load}
          renderTrigger={this.renderTrigger}
          disabled={this.props.data.isPending}
        >
          {this.props.data.isRejected && (
            <DialogContentText color="error">Artikel konnte nicht gelöscht werden.</DialogContentText>
          )}
          {this.props.data.isPending && <PageProgress />}
        </ConfirmDialog>
      </>
    )
  }
}

const getPromise = (id: string) => (): DataPromise =>
  AuthenticationService.fetch<void>(`${config.backend.url}/restricted-articles/${id}`, {
    method: "DELETE",
  }).then((response) => response.body)

export default (props: Omit<Props, "data">): JSX.Element => (
  <Loads defer load={getPromise(props.id)}>
    {(data) => <DeleteRestrictedArticle {...props} data={data} />}
  </Loads>
)
