import { Typography } from "@material-ui/core"
import { Warning as WarningIcon } from "@material-ui/icons"
import React from "react"
import { Link } from "react-router-dom"

import Headline from "../../elements/Headline"
import PageContainer from "../../elements/PageContainer"

interface Props {}

export const NotFound: React.FC<Props> = () => {
  return (
    <PageContainer>
      <Headline variant="h4" component="h1">
        <WarningIcon /> Nicht gefunden
      </Headline>
      <Typography variant="body1">
        Seite konnte nicht gefunden werden. <Link to="/">Zurück zum Start!</Link>
      </Typography>
    </PageContainer>
  )
}

export default NotFound
