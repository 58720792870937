const getEnvironmentValue = <T>(args: { dev?: T; int?: T; prd: T }): T => {
  if (window.location.origin.startsWith("http://localhost")) return args.dev || args.prd
  if (window.location.origin.startsWith("https://kjur-dev-")) return args.dev || args.prd
  return args.prd
}

const deepFreeze = <T extends { [key: string]: unknown }>(obj: T): Readonly<T> => {
  Object.freeze(obj)
  Object.getOwnPropertyNames(obj).forEach((prop) => {
    if (
      // eslint-disable-next-line no-prototype-builtins
      obj.hasOwnProperty(prop) &&
      obj[prop] !== null &&
      (typeof obj[prop] === "object" || typeof obj[prop] === "function") &&
      !Object.isFrozen(obj[prop])
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      deepFreeze(obj[prop] as any)
    }
  })
  return obj
}

export default deepFreeze({
  backend: {
    url: getEnvironmentValue({ prd: "/api" }),
  },
  oauth2: {
    providerUrl: getEnvironmentValue({
      dev: "https://login.microsoftonline.com/bdafdcad-bf72-45a3-86c1-db180da726bd",
      prd: "https://login.microsoftonline.com/bdafdcad-bf72-45a3-86c1-db180da726bd",
    }),
    responseType: getEnvironmentValue<"id_token" | "id_token token" | "code">({ prd: "id_token" }),
    redirectUrl: window.location.origin,
    clientId: getEnvironmentValue({
      dev: "4e3465f3-024b-4a0c-ab00-728d928dd651",
      prd: "abdefa65-9342-4f52-9966-c3c2c7294c4e",
    }),
    scope: "openid profile email",
    routes: [],
    endpoints: [],
    provider: getEnvironmentValue<"azure" | "auth0" | "cognito" | "wso2" | "okta">({ prd: "azure" }),
    loginType: getEnvironmentValue<false | "iframe" | "redirect" | undefined>({ prd: "iframe" }),
    storageType: getEnvironmentValue<"session" | "local">({ prd: "session" }),
  },
})
