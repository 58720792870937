import {
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
} from "@material-ui/core"
import { DateTime } from "luxon"
import React from "react"
import { Loads } from "react-loads"

import config from "../../config"
import Headline from "../../elements/Headline"
import PageContainer from "../../elements/PageContainer"
import PageError from "../../elements/PageError"
import PageLoading from "../../elements/PageLoading"
import Strikethrough from "../../elements/Strikethrough"
import StringToDate from "../../elements/StringToDate"
import AuthenticationService from "../authentication/authentication.service"
import { LoadRenderProps } from "../types"
import AddRestrictedArticle from "./AddRestrictedArticle"
import DeleteRestrictedArticle from "./DeleteRestrictedArticle"
import EditRestrictedArticle from "./EditRestrictedArticle"
import { RestrictedArticle, RestrictedArticlesApiResponse } from "./types"

type DataPromise = Promise<RestrictedArticlesApiResponse>

export interface Props {
  data: LoadRenderProps<Unpromise<DataPromise>>
}

interface State {
  activeOnly: boolean
}

export class RestrictedArticles extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      activeOnly: true,
    }
  }

  renderTitle = (article: RestrictedArticle): string | JSX.Element => {
    const text = `${article.pzn} - ${article.name}`
    if (isArticleInactive(article)) {
      return <Strikethrough>{text}</Strikethrough>
    }
    return text
  }

  private onActiveOnlyChanged = (event: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ activeOnly: event.target.checked })

  render(): JSX.Element {
    return (
      <PageContainer>
        <Headline variant="h4" component="h1">
          Kontingentierte Artikel
        </Headline>
        <FormControlLabel
          control={<Switch checked={this.state.activeOnly} onChange={this.onActiveOnlyChanged} />}
          label="Nur aktive"
        />
        <Paper>
          {this.props.data.isPending && <PageLoading />}
          {this.props.data.isRejected && <PageError>Kontingentierte Artikel konnten nicht geladen werden.</PageError>}
          {this.props.data.isResolved && (
            <>
              <List>
                {this.props.data.response &&
                  this.props.data.response.restrictedArticles
                    .filter((article) => !this.state.activeOnly || !isArticleInactive(article))
                    .map((article, index, list) => (
                      <ListItem key={article.pzn} divider={index !== list.length - 1} alignItems="flex-start">
                        <ListItemText
                          primary={this.renderTitle(article)}
                          secondary={
                            <>
                              <StringToDate value={article.activeFrom} /> -{" "}
                              <StringToDate value={article.activeTo} fallback="Unbegrenzt" />
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <EditRestrictedArticle article={article} onSuccess={this.props.data.load} />
                          <DeleteRestrictedArticle
                            id={article.id}
                            pzn={`${article.pzn}`}
                            onSuccess={this.props.data.load}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                {this.props.data.response && this.props.data.response.restrictedArticles.length === 0 && (
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Keine Artikel gelistet." />
                  </ListItem>
                )}
              </List>
              <AddRestrictedArticle onSuccess={this.props.data.load} />
            </>
          )}
        </Paper>
      </PageContainer>
    )
  }
}

function isArticleInactive(article: RestrictedArticle): boolean {
  return !!article.activeTo && DateTime.fromISO(article.activeTo).toMillis() < DateTime.local().toMillis()
}

const getPromise = (): DataPromise =>
  AuthenticationService.fetch<RestrictedArticlesApiResponse>(`${config.backend.url}/restricted-articles`).then(
    (response) => response.body
  )

export default (): JSX.Element => <Loads load={getPromise}>{(data) => <RestrictedArticles data={data} />}</Loads>
